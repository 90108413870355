* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

:root {
  --primary: #2A7AE4;
  --black: #000000;
  --blackLighter: #9E9E9E;
  --grayLight: #F5F5F5;
  --grayMedium: #e5e5e5; 
  --white: #FFFFFF;
  --frontEnd: #6BD1FF;
  --backEnd: #00C86F;
  --grayDark: #141414;
  --blueDark: #0c0147;
  --videoCard-container-width: 300px;
  --videoCard-container-height: calc(calc(var(--videoCard-container-width) / 16) * 9);
  --videoCard-container-width-mob: 200px;
  --videoCard-container-height-mob: calc(calc(var(--videoCard-container-width-mob) / 16) * 9);
  --videoCard-focus-scale: 1.3;
  --videoCard-focus-scale-mob: 1.2;
  --MenuPaddingTopMobile: 50px;
}

html,
body {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

.hidden {
  display: none !important;
}

.show-flex {
  display: flex !important;
}

#root{
  min-height: calc(100vh - var(--bodyPaddingTop));
  display: flex;
  flex-direction: column;
}

.menu-ativo:before {
  content: '';
  display: block;
  z-index: 100;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background: rgba(0, 0, 0, 0.4);
}

.hidden {
  display: none !important;
}

.show-flex {
  display: flex !important;
}

#root {
  --bodyPaddingTop: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: var(--bodyPaddingTop);
  background: var(--grayDark);
}

@media (max-width: 800px) {
  #root {
    padding-top: var(--MenuPaddingTopMobile);
  }
}